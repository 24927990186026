import React from "react"

import {Container} from "react-bootstrap"
import {Link} from 'gatsby'
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';

import App from "../components/App"
import Header from "../components/Header"
import Spacer from "../components/Spacer"
import Footer from "../components/Footer"
import SEO from "../components/seo"

export default () => (
  <App>
    <SEO title="404 - Nicht gefunden"/>
    <Header />
    <Container>
      <Spacer variant="large"/>
      <Link className="d-block d-md-none" to="/"><KeyboardBackspaceIcon/> Start</Link>
      <h1 className="d-md-block d-none">404 - Nicht gefunden</h1>
      <h3 className="d-block d-md-none">404 - Nicht gefunden</h3>
      <section className="col-12">
        <p>Die von Ihnen aufgerufene Seite exisitiert nicht.</p>
      </section>
      <Spacer variant="large"/>
      <Spacer variant="large"/>
      <Spacer variant="medium"/>
    </Container>
    <section className="bg-dark text-light pt-4">
      <Footer />
    </section>
  </App>
)
